import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Qui est perceptible.fr ?`}</h1>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` a été crée par Alain Roan, Ingénieur Supelec. `}</p>
    <br />
    <img src="/Alain.jpg" width="150" heigth="150" />
    <br />
    <p>{`Il a participé à l'élaboration et la diffusion des méthodes et des technologies visant à l'amélioration des pratiques et des façons de faire de l'ingénierie des systèmes/logiciels complexes et critiques pour la plupart des domaines industriels en Europe et aux États-Unis. Il a occupé des responsabilités qui couvrent une bonne partie de la mosaïque d'une entreprise: R`}{`&`}{`D, développement/ production, services/ conseil, marketing, ventes, support, direction générale. `}</p>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` est à la convergence de différentes forces :`}</p>
    <ul>
      <li parentName="ul">{`la reconnaissance que le maillon faible de la pyramide désormais très haute d'outils et de méthodes, reste (comme pour toujours) la `}<strong parentName="li">{`décision humaine`}</strong></li>
      <li parentName="ul">{`le constat que partout y compris dans les plus grandes entreprises équipées des logiciels de gestion les plus sophistiqués, la plupart des salariés utilisent des tableurs pour mémoriser, calculer et présenter des données qui leur servent à `}<strong parentName="li">{`comprendre`}</strong>{`, `}<strong parentName="li">{`mesurer`}</strong>{`, `}<strong parentName="li">{`expliquer`}</strong>{` et `}<strong parentName="li">{`décider`}</strong></li>
      <li parentName="ul">{`l'avènement de technologies de rupture qui bouleversent et court-circuitent les vieilles façons de penser et de faire. `}<strong parentName="li">{`État des neurosciences`}</strong>{`, `}<strong parentName="li">{`Html 5`}</strong>{`, `}<strong parentName="li">{`open source`}</strong>{`... `}</li>
      <li parentName="ul">{`et la robustesse des `}<strong parentName="li">{`méthodes agiles`}</strong>{` qui à `}<strong parentName="li">{`petits pas`}</strong>{` font de grandes enjambées`}</li>
    </ul>
    <p><strong parentName="p">{`perceptible.fr`}</strong>{` exploite les meilleures technologies disponibles pour rendre le contrôle à la décision humaine: `}<strong parentName="p">{`des données visuelles qui "parlent"`}</strong>{` à leurs destinataires.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      